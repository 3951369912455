import { QueryClient, QueryClientProvider } from '@tanstack/react-query'
import dynamic from 'next/dynamic'

const AdminApp = dynamic(() => import('../components/pages/admin-page'), {
  ssr: false,
})

const queryClient = new QueryClient()

const Main = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <AdminApp />
    </QueryClientProvider>
  )
}

export default Main
